import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

const Home = lazy(() => import("./home"));
const Show = lazy(() => import("./show"));

export default function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/:graphString" exact component={Show} />
        </Switch>
      </Suspense>
    </Router>
  );
}
